import React, { useEffect, useState } from 'react';
import Button from '@ids/button';
import Recaptcha from 'react-recaptcha';
import IndeterminateShort from '@ids/indeterminate-short';
import InlineValidationMessage from '@ids/inline-validation-message';
import { CloseIcon } from '@ids-ts/icon';
import {
  getSavedLogo,
  getUploadImage,
  postUploadImage,
  putUploadLogo,
} from '../../services/customizationOptionsDataService';
import '../../../stylesheets/customizationOptions.scss';

const UploadLogoModal = (props) => {
  const { savedLogo, hideClosIcon } = props;
  const [fileObj, setFileObj] = useState();
  const [fileName, setFileName] = useState('No file selected');
  const [errorMessage, setErrorMessage] = useState('');
  const [displayCaptcha, setCaptcha] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const { host } = window.location;

  const displayCaptchaOnLoad = () => {
    console.log('recaptcha loaded');
  };

  const postUploadedImage = (fileData) => {
    const existingFileType = new RegExp(
      /\.(eps|postscript|jpg|jpeg|pdf|gif|tif|wmf|bmp|png|bitmap|ai)$/i,
    );
    const fileNamePattern = new RegExp(/^[a-zA-Z0-9-_]+$/);
    if (!fileData) {
      setErrorMessage('Please select a file to upload');
    } else if (displayCaptcha && (captchaResponse === '' || captchaResponse === null)) {
      setErrorMessage(
        "Hold on: you'll need to prove you're a human before you can upload another logo. Please try again",
      );
    } else {
      if (fileData.name.length >= 100) {
        setErrorMessage('The logo file name has to be less than 100 characters.');
        return;
      }
      if (fileData.size > 5240000) {
        setErrorMessage(
          "The image you're trying to upload is too large. Please make sure your logo is 5MB or less. ",
        );
        return;
      }
      const fileExt = fileData.name.substr(fileData.name.lastIndexOf('.'), fileData.name.length);
      if (!existingFileType.test(fileExt)) {
        setErrorMessage(
          "We can't use the file type you're trying to upload. Please use an Adobe Illustrator EPS image file. We also accept JPG, GIF, TIF, WMF, AI, PDF, BMP, and PNG bitmap images. Please select format mentioned in the list",
        );
        return;
      }
      if (savedLogo.filter((logo) => logo.LogoLabel === fileData.name).length !== 0) {
        setErrorMessage('Image Name exists. Please change the image.');
        return;
      }
      const imageFileName = fileData.name.substr(0, fileData.name.lastIndexOf('.'));
      if (!fileNamePattern.test(imageFileName)) {
        setErrorMessage(
          'The logo file name can only contain letters, numbers, the hyphen character (-) and/or the underscore character (_).',
        );
        return;
      }
      setShowLoader(true);
      postUploadImage(fileData, captchaResponse)
        .then((res) => {
          setErrorMessage('');
          putUploadLogo(res.imageguid, fileData.name).then(() => {
            getSavedLogo().then((resp) => {
              setShowLoader(false);
              props.onUploadComplete(resp);
            });
          });
        })
        .catch((err) => {
          // reset captcha
          setCaptchaResponse('');
          setErrorMessage("Image can't be processed. Please try after some time");
          console.log(err);
          setShowLoader(false);
        });
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    postUploadedImage(fileObj);
  };

  const verifyCallback = (response) => {
    setCaptchaResponse(response);
    if (response !== '' || response !== null) {
      setCaptcha(false);
      setErrorMessage('');
    }
  };

  const handleBrowse = (e) => {
    e.preventDefault();
    document.getElementById('file-upload').click();
  };

  const handleFileNameChange = (e) => {
    e.preventDefault();
    setFileObj(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  useEffect(() => {
    getUploadImage((res) => {
      if (res.displaycaptcha === true) {
        setCaptcha(res.displaycaptcha);
      } else {
        setCaptcha(res.displaycaptcha);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`upload-drawer ${props.isCustomOption ? 'upload-drawer-width' : ''}`}>
      <h3 className={`upload-modal-header ${props.isCustomOption ? 'upload-modal-header-oh' : ''}`}>
        <div>
          Upload your own logo{' '}
          {!hideClosIcon && (
            <span className="close-upload" onClick={props.close} style={{ marginTop: '-25px' }}>
              <CloseIcon size="small" />
            </span>
          )}
        </div>
        <div className="upload-modal-subHeader">
          Click <b>Browse</b> to find your logo file then click <b>Open</b>
        </div>
      </h3>

      <ul
        className={`upload-modal-subHeader upload-list ${
          props.isCustomOption ? 'upload-list-oh' : ''
        }`}
      >
        <li>There is a one-time set up fee of $9.99 to add a logo.</li>
        <li>Adding your own logo will increase printing time by 5-6 days.</li>
        <li>
          {' '}
          Logos uploaded through the website can only be printed in black and white. To use a color
          logo, call customer service at (866) 570-3842, Mon. - Fri., 9am-8pm ET.
        </li>
      </ul>

      <form className="upload-image" id="upload-logo" style={{ marginTop: '-6px' }}>
        {props.displayCaptcha && (
          <div className="captcha-container">
            <Recaptcha
              sitekey="6LegCwkUAAAAAG0M2s-UsXaOapMKJoE0MiJD48yS"
              render="explicit"
              onloadCallback={displayCaptchaOnLoad}
              verifyCallback={verifyCallback}
            />
          </div>
        )}
        {showLoader ? (
          <div style={{ textAlign: 'center' }}>
            <IndeterminateShort theme="quickbooks" />
          </div>
        ) : (
          <>
            <div>
              <Button
                buttonType="secondary"
                theme="quickbooks"
                onClick={(e) => handleBrowse(e)}
                data-wa-link="browse"
                for="file-upload"
                className="upload-image-button"
                data-ui-object="button"
                data-refer-ui-access-point="upload-logo_modal | browse"
              >
                Browse...
              </Button>
              <label className="file-name-label font-wgt-500">{fileName}</label>
              <input
                type="file"
                id="file-upload"
                className="upload-file"
                onChange={(e) => handleFileNameChange(e)}
                style={{ display: 'none' }}
              />
            </div>
            {errorMessage.length > 0 && (
              <div className={`${props.isCustomOption ? 'err-msg-oh' : 'err-msg'}`}>
                <InlineValidationMessage type="warning" message={errorMessage} />
              </div>
            )}
            <Button
              buttonType="primary"
              theme="quickbooks"
              onClick={handleUpload}
              data-wa-link="upload logo "
              className={`${errorMessage.length <= 0 ? 'margin-top-20' : ''}`}
              data-ui-object="button"
              data-refer-ui-access-point="upload-logo_modal | upload"
            >
              Upload
            </Button>
          </>
        )}
      </form>
      <div className="upload-tips-container">
        <div className="upload-tips-subcontainer">
          <div className="upload-tips-header">Tips for uploading images</div>
          <ul className="upload-tips">
            <li>
              For best results, use an EPS file. You can also upload a JPG, GIF, TIF, PDF, PNG, or
              BMP file.
            </li>
            <li>The logo file name has to be less than 100 characters.</li>
            <li>
              The logo file name can only contain letters, numbers, hyphen character (-) and/or the
              underscore character (_).
            </li>
            <li>Make sure your image file size is less than 5MB. </li>
            <li>
              The image should be larger than 600x600 pixels; smaller images will not print clearly.
            </li>
          </ul>
        </div>
      </div>
      <p className="upload-tAndc">
        You understand that all content, whether publicly posted or privately transmitted is the
        sole responsibility of the person from which such content originated. This means that you,
        and not Intuit, are entirely responsible for all content that you upload or otherwise
        transmit via the services. Intuit does not control the content uploaded or otherwise
        transmitted by you or other customers via the services and, as such, does not guarantee the
        accuracy, integrity, or quality of such content.
      </p>
      <p className="upload-tAndc margin-top-15">
        <a
          href={`https://${host}/file-upload-disclaimer`}
          target="_blank"
          data-di-id=""
          className="upload-link"
          rel="noopener noreferrer"
        >
          Read Intuit’s acceptable use policy
        </a>
      </p>
    </div>
  );
};

export default UploadLogoModal;
