import BlueIcon from '../images/IM/TaxReturnFolder/Blue.png';
import BrambleIcon from '../images/IM/TaxReturnFolder/Bramble.png';
import CharcoalIcon from '../images/IM/TaxReturnFolder/Charcoal.png';
import RegalIcon from '../images/IM/TaxReturnFolder/Regal.png';
import GreenIcon from '../images/IM/TaxReturnFolder/Green.png';
import HunterIcon from '../images/IM/TaxReturnFolder/Hunter.png';

export const fallBackSearchResults = {
  TypedDocuments: [
    {
      Fields: {
        Title: {
          Value: 'Business Checks: Order Checks Online for Intuit QuickBooks',
        },
        Url: {
          Value: `${window.location.origin}/checks`,
        },
        Description: {
          Value: 'Spend less time printing checks and more time running your business',
        },
      },
    },
    {
      Fields: {
        Title: {
          Value: 'Federal Tax Forms: Order QuickBooks Tax Forms',
        },
        Url: {
          Value: `${window.location.origin}/tax-forms`,
        },
        Description: {
          Value:
            'Our tax kits make tax time less taxing. Check out our tax kits for small businesses.',
        },
      },
    },
    {
      Fields: {
        Title: {
          Value: 'Business Envelopes: Business Sized Envelopes for QuickBooks Products',
        },
        Url: {
          Value: `${window.location.origin}/envelopes`,
        },
        Description: {
          Value:
            'Designed for optimal compatibility with QuickBooks and Intuit products, these envelopes provide a professional business appearance.',
        },
      },
    },
    {
      Fields: {
        Title: {
          Value: 'Bank Deposit Slips: Order Business Deposit Slips for QuickBooks',
        },
        Url: {
          Value: `${window.location.origin}/tax-forms`,
        },
        Description: {
          Value:
            'Prepare deposits easily with QuickBooks Deposit Slips for your business. Order the quick-and-easy printable deposit slips or the manual deposit slips you fill out by hand.',
        },
      },
    },
    {
      Fields: {
        Title: {
          Value: 'More supplies',
        },
        Url: {
          Value: `${window.location.origin}/supplies`,
        },
        Description: {
          Value: '',
        },
      },
    },
    {
      Fields: {
        Title: {
          Value: 'Helping Protect Businesses from Identity Theft | QuickBooks Detect & Defend',
        },
        Url: {
          Value: `${window.location.origin}/qb-detect-and-defend`,
        },
        Description: {
          Value:
            'QuickBooks Detect & Defend offers powerful features to help protect your business - we continually monitor the Dark Web for your information, notify you right away about suspicious activity, and help you with the resolution if fraud occurs.',
        },
      },
    },
  ],
  IsSuccess: true,
  isFallbackResults: true,
};

export const numberOfSearchResultPerPage = 10;

export const SEOMetaDetails = {
  '/': {
    MetaDescription:
      'Business checks, envelopes, and  supplies designed to work seamlessly with  QuickBooks and Intuit products. ',
    TitleTag: 'Small Business Supplies from Intuit Market | QuickBooks',
  },
  '/blankperfw2': {
    MetaDescription:
      'W2 forms & kits designed to work seamlessly with QuickBooks Online and QuickBooks Payroll.',
    TitleTag: 'Blank W2 Form Kits With Envelopes Made For QuickBooks Online',
  },
  '/tax-forms/w2-forms/w-2-blank-envelope-kit': {
    MetaDescription:
      'W2 forms & kits designed to work seamlessly with QuickBooks Online and QuickBooks Payroll.',
    TitleTag: 'Blank W2 Form Kits With Envelopes Made For QuickBooks Online',
    path: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Blank W-2 Kits", "sku": "PERF-01", "mpn": "276-00010", "brand":"QuickBooks","description":"Quickbooks® Laser W-2 Blank Perforated Paper & Envelope Kits contain blank perforated W-2 paper and compatible double-window envelopes. Compatible with Quickbooks® Online, Intuit Online Payroll and QuickBooks for Mac." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_w2b_120.gif", "offers" : { "@type" : "Offer", "price" : "31.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/w2-forms/w-2-blank-envelope-kit" } }',
  },
  '/laser-w-2-blank-envelope-kit': {
    path: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
  },
  '/tax-forms/w2-forms/w-2-blank-envelope-kit': {
    MetaDescription:
      'W2 forms & kits designed and guaranteed to work seamlessly with QuickBooks Online and QuickBooks Payroll.',
    TitleTag: 'Blank W2 Form Kits With Envelopes Made For QuickBooks Online',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Blank W-2 Kits", "sku": "PERF-01", "mpn": "276-00010", "brand":"QuickBooks","description":"Quickbooks® Laser W-2 Blank Perforated Paper & Envelope Kits contain blank perforated W-2 paper and compatible double-window envelopes. Compatible with Quickbooks® Online, Intuit Online Payroll and QuickBooks for Mac." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_w2b_120.gif", "offers" : { "@type" : "Offer", "price" : "31.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/w2-forms/w-2-blank-envelope-kit" } }',
  },
  '/bsbc': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/bvc': {
    MetaDescription:
      'Basic voucher checks, envelopes & office supplies designed to work seamlessly with QuickBooks and QuickBooks Payroll.',
    TitleTag: 'Basic Voucher Checks: QuickBooks & Payroll Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Basic Voucher Checks", "sku": "CHECK-02", "mpn": "145-00050", "brand":"QuickBooks","description":"Designed exclusively for use with QuickBooks. Use these checks to track payroll deductions and invoice payment details with convenient check stubs." ,"image" : "https://intuitmarket.intuit.com/fsg_repository/chk/chk_v21_120.gif", "offers" : { "@type" : "Offer", "price" : "36.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/bvc" } }',
  },
  '/bwc': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/check-21': {
    MetaDescription:
      'Quickbooks check 21 compliant checks save time and money and are guaranteed accepted by all U.S. financial institutions.',
    TitleTag: 'Check 21 Act - Check Clearing Process For 21st Century Act',
  },
  '/checks': {
    MetaDescription:
      'QuickBooks business checks - order online for convenience - come in a variety of styles, colors and unique security features.',
    TitleTag: 'Business Checks: Order Checks Online for Intuit QuickBooks',
  },
  '/checks-tax-combo': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Tax Supplies',
  },
  '/checks/direct-deposit-forms': {
    MetaDescription:
      'Track direct deposit amounts and payroll deductions to vendor payment details and employee paychecks quickly QuickBooks Direct Deposit Forms.',
    TitleTag: 'Direct Deposit Forms For Employees, Payroll & Paychecks',
  },
  '/checks/manual-checks': {
    MetaDescription:
      'Get 3 to a page manual business checks with or without a check binder. Enjoy the convenience of having a set of secure, quality checks on hand.',
    TitleTag: 'Manual Business Checks With Binder 3 To A Page With Stubs',
  },
  '/checks/office-away-checks': {
    MetaDescription:
      "Office and Away Checks allow you to print in your office directly from QuickBooks Desktop or handwrite when you're away.",
    TitleTag: 'QuickBooks Office and Away Checks',
  },
  '/checks/personal-checks': {
    MetaDescription:
      'Secure Plus Personal Checks help protect against check fraud from the fraud protection experts at QuickBooks.',
    TitleTag: 'Secure Personal Checks: Custom, Printable or Manual',
  },
  '/checks/printing': {
    MetaDescription:
      'Secure tamper proof printable checks designed to integrate perfectly with your QuickBooks software',
    TitleTag: 'QuickBooks Printable Business Checks: Check Printing for QuickBooks',
  },
  '/checks/standard-checks': {
    MetaDescription:
      'Perfect for paying vendors or other bills, 3 to a page standard business checks work with all versions of QuickBooks.',
    TitleTag: '3 To A Page Standard Business Checks For QuickBooks',
  },
  '/checks/voucher-checks': {
    MetaDescription:
      'Print high security 1-per-page voucher checks designed to work perfectly and print directly from QuickBooks for fast and easy payment tracking.',
    TitleTag: 'Voucher Checks For Payroll & Vendor Payment',
  },
  '/checks/wallet-checks': {
    MetaDescription:
      'Avoid the need for duplicate or triplicate checks and benefit from the ease of single printable wallet checks synced and reconciled with QuickBooks.',
    TitleTag: 'Wallet Checks: Single Checks For Business & Personal Use',
  },
  '/resources': {
    MetaDescription:
      'Information about checks and tax forms products businesses need to know. From the basics to more advanced details.',
    TitleTag: 'Checks, Tax Forms & Fraud Prevention Resources | QuickBooks',
  },
  '/resources/business-fraud-prevention': {
    MetaDescription:
      'Small businesses can pay a high price for business fraud: learn about some of the common scams and ways you can help protect your business.',
    TitleTag: 'Don’t Let Your Business Become A Victim Of Fraud',
  },
  '/resources/business-fraud-prevention/10-ways-employees-stop-scams': {
    MetaDescription:
      'Cybercriminals are waiting outside the proverbial door to steal confidential financial data and more. And guess who lets them in?',
    TitleTag: '10 Ways Your Employees Can Help You Prevent Fraud',
  },
  '/resources/business-fraud-prevention/6-scams-to-know-and-avoid': {
    MetaDescription:
      'Regardless of the size of your company, scammers are out to steal from you. Learn about 6 of the most common scams that small businesses can avoid.',
    TitleTag: '6 Scams and Fraud Schemes For Small Businesses To Avoid',
  },
  '/resources/business-fraud-prevention/tax-scams-to-avoid': {
    MetaDescription:
      'Learn three of the most common IRS tax scams that can cost your business and some basic steps you can take to avoid them.',
    TitleTag: 'IRS Tax Scams & How Your Business Can Avoid Fraud',
  },
  '/resources/fraud-prevention': {
    MetaDescription:
      'Learn about the type of check fraud businesses are most susceptible to and strategies to help prevent it. How are you preventing check fraud?',
    TitleTag: 'How Checks Prevent Check Fraud, Forgeries & Counterfeiting',
  },
  '/resources/fraud-prevention/counterfeiting': {
    MetaDescription:
      'What does check fraud mean for businesses and how do they prevent it? Find out some of the facts and figures behind fraud.',
    TitleTag: 'Counterfeit Prevention: Fake, Washed, & Tampered Check Signs',
  },
  '/resources/fraud-prevention/5-things-to-look-for': {
    MetaDescription:
      'Minimize the risk of check and payment fraud by implementing these five protocols in your business now.',
    TitleTag: '5 Check Security Tips That Can Help Save Your Business',
  },
  '/resources/fraud-prevention/security-check-features': {
    MetaDescription:
      'See the features that matter most in helping to prevent fraud and how Checks & Supplies’ secure checks actively help prevent fraud.',
    TitleTag: 'Secure Check Features: Microprinting, Watermarks, & Holograms',
  },
  '/resources/fraud-prevention/it-can-happen-to-you': {
    MetaDescription:
      'Secure checks are only one part of protecting your business from check fraud.',
    TitleTag: 'Check Fraud Can Happen To You: One Accountant’s Story.',
  },
  '/resources/checks': {
    MetaDescription:
      'Our guide to (almost) everything you need to know about choosing, buying, and ordering business checks.',
    TitleTag: 'Business Checks Guide: What To Know Before Buying Or Signing',
  },
  '/resources/checks/types-of-business-checks-why-it-matters': {
    MetaDescription:
      'Are you using the right check for the right application for your business? Learn about the types of business checks you can use and when to use them.',
    TitleTag: 'Types Of Business Checks & Why It Matters',
  },
  '/resources/checks/business-check-mistakes': {
    MetaDescription:
      'Learn about the most common and important check mistakes that businesses make and what you can do to avoid them and avoid unnecessary risk.',
    TitleTag: '7 Most Common Business Check Mistakes to Avoid',
  },
  '/resources/checks/4-questions-to-ask-when-choosing-checks': {
    MetaDescription:
      'Ask these four simple questions to understand which business checks are best for your business.',
    TitleTag: 'Find The Best Business Check For Your Business: 4 Questions To Ask',
  },
  '/resources/checks/what-is-a-business-check': {
    MetaDescription:
      'Business checks differ than personal checks or cashier checks in a few important ways. Learn more from the check professionals at Intuit Market.',
    TitleTag: 'What Is A Business Check? Parts & Formatting Compared',
  },
  '/resources/checks/endorsements': {
    MetaDescription:
      'Learn the proper protocol and special exceptions to cashing a check made out to your business.',
    TitleTag: 'How To Endorse Business Checks According To Regulation',
  },
  '/resources/checks/check-21': {
    MetaDescription:
      'Check 21 is an industry standard in checks, learn why and how it can help prevent fraud will speeding up payments.',
    TitleTag: 'Check 21 Compliance & Clearing Standards For Business Checks',
  },
  '/resources/tax-forms': {
    MetaDescription:
      "We've gathered some of the best resources for small businesses on tax forms from QuickBooks and Intuit.",
    TitleTag: 'Tax Form Resources For Small Business From QuickBooks',
  },
  '/resources/tax-forms/w2-w3': {
    MetaDescription:
      'Learn about W-2 forms from the tax, accounting software, and small business supplies professionals at QuickBooks',
    TitleTag: 'W-2 Resources From The Tax & Supplies Pros At QuickBooks',
  },
  '/resources/tax-forms/1099-1096': {
    MetaDescription:
      'Learn about 1099 forms from the tax, accounting software, and small business supplies professionals at QuickBooks',
    TitleTag: '1099 Resources From The Tax & Supplies Pros At QuickBooks',
  },
  '/resources/fraud-prevention/check-counterfeiting': {
    MetaDescription:
      'Fake checks hurt business owners. Learn what to look for, what to do if a fake check or fake check scheme happens, and what it takes to protect your business.',
    TitleTag: 'Counterfeit Checks: Washing, Tampering, & Counterfeit Scams',
  },
  '/resources/fraud-prevention/security-check-features': {
    MetaDescription:
      "Learn about the features that matter most in preventing fraud and see why Intuit Market's secure checks actively help prevent fraud.",
    TitleTag: 'Secure Check Features: Microprinting, Watermark, & Holograms',
  },
  '/resources/fraud-prevention/facts': {
    MetaDescription:
      'What does check fraud mean for businesses and how do they prevent it? Find out some of the facts and figures behind fraud and what it means for your business.',
    TitleTag: 'Facts & Tips About Check Fraud Your Business Should Know',
  },

  '/checkstaxipd': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'Intuit Market Tax Supplies',
  },
  '/deposit-slips': {
    MetaDescription:
      'Record keeping made easy with QuickBooks custom deposit slips and printable deposit slips on any standard printer.',
    TitleTag: 'Bank Deposit Slips: Order Business Deposit Slips',
  },
  '/deposit-slips/disposable-deposit-bags': {
    MetaDescription:
      'QuickBooks bank deposit bags are convenient and disposable. An easy way to deposit cash, checks and coins. Numbered removable deposit receipt. Recyclable.',
    TitleTag: 'Bank Deposit Bags: Disposable Plastic Bags for Cash & Coins',
  },
  '/deposit-slips/manual': {
    MetaDescription:
      'Speed up deposits by using slips that have your business name and account number pre-printed on them.',
    TitleTag: 'Manual Deposit Slips: Pre-print Business Name & Bank Account',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Booked Manual Deposit Slips", "sku": "DEP-01", "mpn": "2364-0200", "brand":"QuickBooks","description":"Speed up deposits by using QuickBooks® slips that have your business name and account number pre-printed. Make up to 18 entries per slip. Available in 2- or 3-copy carbonless versions for easy record-keeping. Designed exclusively for use with QuickBooks. An Intuit Marketplace product." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/dps/dps_man_120.gif", "offers" : { "@type" : "Offer", "price" : "43.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/deposit-slips/manual" } }',
  },
  '/deposit-slips/printable': {
    MetaDescription:
      'Print your own deposit slips directly from QuickBooks. Save time while increasing bookkeeping accuracy. Record up to 18 transactions per slip.',
    TitleTag: 'Printable Deposit Slips Formatted & Designed For QuickBooks',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Printable Deposit Slips", "sku": "DEP-02", "mpn": "1021-0250", "brand":"QuickBooks","description":"Stop manually filling out business deposit slips by hand! Save time by printing deposit slips using information already in QuickBooks. Improve record-keeping accuracy by avoiding double entry errors.Each slip has a deposit summary stub for easy record-keeping" ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/dps/dps_prt_120.gif", "offers" : { "@type" : "Offer", "price" : "68.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/deposit-slips/printable" } }',
  },
  '/discontinued': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/envelopes/custom-printed-envelopes/business-reply': {
    MetaDescription:
      'Save time and collect money faster with QuickBooks business reply envelopes designed specifically to work with QuickBooks Remittance Statements',
    TitleTag: 'Business Reply Envelopes: QuickBooks Custom Return Envelopes',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Business Reply Envelopes", "sku": "ENV-07", "mpn": "517-00250", "brand":"QuickBooks","description":"Get paid faster by enclosing a convenient QuickBooks® pre-printed return envelope with your invoices. Look more professional by having your address pre-printed. QuickBooks® Business Reply Envelopes work perfectly with our Remittance Statements. Designed" ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_biz_reply_120.gif", "offers" : { "@type" : "Offer", "price" : "54.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/envelopes/custom-printed-envelopes/business-reply" } }',
  },
  '/envelopes/check-window': {
    MetaDescription:
      'Perfectly sized for QuickBooks check products our secure business envelopes save time and present a professional image.',
    TitleTag: 'Check Envelopes: Window Envelopes Designed for QuickBooks',
  },
  '/envelopes/custom-printed-envelopes': {
    MetaDescription:
      'QuickBooks custom envelopes come in a variety of sizes and types- add your company logo, name and address to reinforce a professional image. ',
    TitleTag: 'Custom Business Envelopes: Custom Sizes & Printed Logo',
  },
  '/envelopes/form-envelopes/forms-envelopes': {
    TitleTag: 'Dual Window Envelopes For Forms | QuickBooks Checks & Supplies',
  },
  '/envelopes/form-envelopes': {
    MetaDescription:
      'QuickBooks Form Envelope aligns perfectly with all QuickBooks pre-printed forms and forms printed on plain paper from QuickBooks.',
    TitleTag: 'Standard Envelope Sizes For QuickBooks: Convenient & Secure',
  },
  '/envelopes/custom-printed-envelopes/large-mailing-envelopes': {
    MetaDescription:
      'Large, customized mailing envelopes. Promote your brand. Mail large documents. Avoid folding important documents.',
    TitleTag: 'Large Envelopes & Large Mailing Envelopes in Different Sizes',
  },
  '/envelopes/form-envelopes/self-seal-form-envelopes': {
    MetaDescription:
      'Our self-sealing adhesive form envelopes are designed to perfectly fit with QuickBooks checks and business forms.',
    TitleTag: 'Self-Seal Envelopes: Adhesive Sealing Envelopes | QuickBooks',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Self-Seal Forms Envelopes", "sku": "ENV-09", "mpn": "331-00250", "brand":"QuickBooks","description":"Save time with self-sealing adhesive QuickBooks® form envelopes. This envelope aligns perfectly with all pre-printed forms and forms printed on plain paper from QuickBooks®." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_ss_dw_forms_120.gif", "offers" : { "@type" : "Offer", "price" : "75.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/envelopes/form-envelopes/self-seal-form-envelopes" } }',
  },
  '/envelopes/check-window/self-seal-voucher': {
    MetaDescription:
      'Save time & hassle with self seal standard & voucher check envelopes - perfectly fit QuickBooks products to keep sensitive information confidential.',
    TitleTag: 'Self Seal Voucher Check Window Envelopes',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Self-Seal Voucher Check Envelopes", "sku": "ENV-08", "mpn": "229-00050", "brand":"QuickBooks","description":"Save time with QuickBooks® self-sealing adhesive envelopes for both Standard & Voucher checks. Built-in security tabs help you identify tampering and keeps sensitive information confidential.  Window aligns perfectly with check information. Designed excl" ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_ss_dw_stand_vouch_120.gif", "offers" : { "@type" : "Offer", "price" : "30.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/envelopes/check-window/self-seal-voucher" } }',
  },
  '/envelopes/form-envelopes/single-window-form-envelopes': {
    MetaDescription:
      'QuickBooks single window form envelopes - single window form envelopes pre-printed with your company information look professional and protected.',
    TitleTag: 'Single Window Form Envelopes For Business | QuickBooks',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Single Window Form Envelopes", "sku": "ENV-05", "mpn": "510-00250", "brand":"QuickBooks","description":"Skip hand-addressing altogether with QuickBooks® Single Window Form Envelopes – a single window reveals the mailing address and your company\'s return address is pre-printed on the upper left. Look more professional with envelopes that show your company\'s" ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_sw_forms_120.gif", "offers" : { "@type" : "Offer", "price" : "73.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/envelopes/form-envelopes/single-window-form-envelopes" } }',
  },
  '/envelopes/check-window/voucher-checks': {
    MetaDescription:
      'Check envelopes designed and guaranteed to work with voucher and payroll checks from the makers of QuickBooks.',
    TitleTag: 'Voucher Check & Payroll Check Envelopes',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Standard Voucher Check Envelopes", "sku": "ENV-01", "mpn": "873-00250", "brand":"QuickBooks","description":"Save time and avoid the hassle of manually addressing envelopes. Look more professional with QuickBooks® double window envelopes. Windows align perfectly with our QuickBooks® Standard and Voucher Checks, keeping sensitive information confidential. Design" ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_dw_stand_vouch_120.gif", "offers" : { "@type" : "Offer", "price" : "68.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/envelopes/check-window/voucher-checks" } }',
  },
  '/envelopes/check-window/wallet-check-envelopes': {
    MetaDescription:
      'QuickBooks Wallet Check Envelopes are custom made to fit QuickBooks wallet checks, providing a neat, professional appearance. ',
    TitleTag: 'Wallet Check Envelopes: QuickBooks Wallet Sized Check Envelopes',
  },
  '/form-envelopes/forms-envelopes': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'Double Window Form Envelopes Designed For QuickBooks Forms',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Forms Envelopes", "sku": "ENV-04", "mpn": "208-00250", "brand":"QuickBooks","description":"Save time with QuickBooks® convenient self-sealing adhesive envelopes which perfectly align with all QuickBooks® Pre-Printed Forms and forms printed on plain paper from QuickBooks®. Look more professional & keep sensitive information confidential." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_dw_forms_120.gif", "offers" : { "@type" : "Offer", "price" : "68.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/form-envelopes/forms-envelopes" } }',
  },
  '/supplies/forms/all-purpose-forms': {
    MetaDescription:
      'Saves time and streamlines your shipping by printing a specially designed multi-purpose form and mailing label in one easy step.',
    TitleTag: 'QuickBooks All Purpose Form with Peel-off Label',
  },
  '/supplies/forms/blank-remittance-statements': {
    MetaDescription:
      'Blank remittance statments for QuickBooks have a detachable payment stubs which make it easy for customers to mail their payments.',
    TitleTag: 'Remittance Statements for QuickBooks',
  },
  '/forms/business-statements': {
    MetaDescription:
      'Streamline billing correspondence with QuickBooks business statements. Clearly communicate customer account status and make simplify remittance. ',
    TitleTag: 'Statement Forms: Business, Remittance, Billing & Financial Statements',
  },
  '/supplies/forms/bussiness-statements/billing-statements': {
    MetaDescription: 'Conveniently print account statements directly from QuickBooks.',
    TitleTag: 'Billing Statements for QuickBooks',
  },
  '/supplies/forms/form-leaders': {
    MetaDescription:
      'Attach Form Leaders to individual checks and then print as a full sheet directly from QuickBooks to save time and automatically record check data.',
    TitleTag: 'QuickBooks Form Leaders - Print Single Checks As Full Sheets',
  },
  '/forms/invoices': {
    MetaDescription:
      'QuickBooks preprinted invoice forms integrate seamlessly with QuickBooks software and come in three useful designs  - Product, Service, and Professional.',
    TitleTag: 'Blank Business Invoices: Invoice Forms & Templates for QuickBooks',
  },
  '/supplies/forms/multi-purpose-forms-detail': {
    MetaDescription:
      'Flexible and customizable format is ideal for Estimates, Credit Memos and Purchase Orders.',
    TitleTag:
      'Customizable forms for QuickBooks - Great for Estimates, Credit Memos and Purchase Orders',
  },
  // Non-Indexable Links
  '/supplies/forms/multi-purpose-forms/multi-purpose-forms/customization': {
    path: '/supplies/forms/multi-purpose-forms',
  },
  '/checks/voucher-checks/secure-premier-voucher-checks/customization': {
    path: '/checks/voucher-checks',
  },
  '/checks/voucher-checks/basic-voucher-checks/customization': {
    path: '/checks/voucher-checks',
  },
  '/checks/standard-checks/secure-premier-standard-business-checks/customization': {
    path: '/checks/standard-checks',
  },
  '/checks/standard-checks/basic-standard-business-checks/customization': {
    path: '/checks/standard-checks',
  },
  '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization': {
    path: '/envelopes/form-envelopes',
  },
  '/stampsandlabels/endorsement-stamps/customization': {
    path: '/stampsandlabels/endorsement-stamps',
  },
  '/checks/personal-checks/customization': {
    path: '/checks/personal-checks',
  },
  '/checks/wallet-checks/basic-wallet-checks/customization': {
    path: '/checks/wallet-checks',
  },
  '/envelopes/custom-printed-envelopes/business-reply-envelopes/customization': {
    path: '/envelopes/custom-printed-envelopes',
  },
  '/deposit-slips/printable/customization': {
    path: '/deposit-slips/printable',
  },
  '/envelopes/custom-printed-envelopes/large-mailing-envelopes/customization': {
    path: '/envelopes/custom-printed-envelopes/large-mailing-envelopes',
  },
  '/deposit-slips/manual/customization': {
    path: '/deposit-slips/manual',
  },
  '/checks/standard-checks/secure-plus-standard-business-checks/customization': {
    path: '/checks/standard-checks',
  },
  '/checks/voucher-checks/secure-plus-voucher-checks/customization': {
    path: '/checks/voucher-checks',
  },
  '/checks/wallet-checks/secure-plus-wallet-checks/customization': {
    path: '/checks/wallet-checks',
  },
  // Indexable Links
  '/supplies/forms/invoices/product/customization': {
    path: '/supplies/forms/invoices',
  },
  '/supplies/forms/invoices/professional/customization': {
    path: '/supplies/forms/invoices',
  },
  '/supplies/forms/invoices/service/customization': {
    path: '/supplies/forms/invoices',
  },
  '/supplies/forms/business-statements/remittance/customization': {
    path: '/supplies/forms/business-statements',
  },
  '/supplies/forms/business-statements/customer/customization': {
    path: '/supplies/forms/business-statements',
  },
  '/stampsandlabels/return-address-stamps/customization': {
    path: '/stampsandlabels/return-address-stamps',
  },
  '/stampsandlabels/monogram-stamps/customization': {
    path: '/stampsandlabels/monogram-stamps',
  },
  '/stampsandlabels/fax-cover-stamps/customization': {
    path: '/stampsandlabels/fax-cover-stamps',
  },
  '/forms/multi-purpose-forms': {
    MetaDescription:
      'Use high-quality, pre-printed Intuit forms for issuing financial communications directly from QuickBooks Desktop.',
    TitleTag:
      'Pre-printed Intuit forms for issuing financial communications directly from QuickBooks',
  },
  '/supplies/forms/product-invoices': {
    MetaDescription:
      'Include separate "Bill to" and "Ship to" fields and headings for quantity, item code, description, unit price and total amount.',
    TitleTag: 'Product Invoices for use with QuickBooks - Ideal for retailers and wholesalers',
  },
  '/supplies/forms/professional-invoices': {
    MetaDescription: 'Stand out from the crowd with this format designed for professional firms.',
    TitleTag: 'Custom Invoices for QuickBooks: Ideal for professionals who bill on a project basis',
  },
  '/supplies/forms/remittance-statements': {
    MetaDescription:
      'Convenient perforated, detachable payment stub makes it easy for customers to remit their payments.',
    TitleTag: 'Custom Remittance Statements for QuickBooks',
  },
  '/supplies/forms/service-invoices': {
    MetaDescription: 'Flexible format lets you create Estimates, Credit Memos and Purchase Orders.',
    TitleTag: 'Custom Invoices for QuickBooks: Ideal for service-oriented businesses',
  },
  '/frank-abagnale': {
    MetaDescription:
      'Information and tips from FBI  expert Frank Abagnale Jr. on how to avoid being the victim of check fraud. ',
    TitleTag: 'Fraudulent Check Scams Prevention by Frank Abagnale Jr.',
  },
  '/quickbooks-landing': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/spsbc': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/spsc': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/spvc': {
    MetaDescription:
      'Secure plus voucher checks, envelopes & office supplies designed to work seamlessly with QuickBooks and QuickBooks Payroll.',
    TitleTag: 'Secure Plus Voucher Checks: QuickBooks & Payroll Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Secure Plus Voucher Checks", "sku": "CHECK-11", "mpn": "1785-00050", "brand":"QuickBooks","description":"Designed exclusively for use with QuickBooks. Get fraud-fighting security features, including an exclusive maximum-protection toner-adhesion strip that makes it easy to spot attempts to lift off print and other signs of paper tampering. An Intuit Marketplace product." ,"image" : "https://intuitmarket.intuit.com/fsg_repository/chk/secure_plus_voucher_small_image.gif", "offers" : { "@type" : "Offer", "price" : "66.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/spvc" } }',
  },
  '/spvcpremier': {
    MetaDescription:
      'Secure premier voucher checks, envelopes & office supplies designed to work seamlessly with QuickBooks and QuickBooks Payroll.',
    TitleTag: 'Secure Premier Voucher Checks: QuickBooks & Payroll Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Secure Premier Voucher Checks", "sku": "CHECK-16", "mpn": "2785-00050", "brand":"QuickBooks","description":"Designed exclusively for use with QuickBooks. Get maximum fraud protection with all the scam-fighting features of Secure Plus Voucher Checks plus a security hologram that\'s easy to verify but hard to copy. These checks are virtually impossible to counterfeit. An Intuit Marketplace product." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/chk/sp_premiumvoucher_chk_120.gif", "offers" : { "@type" : "Offer", "price" : "72.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/spvcpremier" } }',
  },
  '/spwc': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'QuickBooks Supplies',
  },
  '/supplies/labels/address-labels-bml60': {
    MetaDescription:
      '1" x 2 5/8" easy peel labels integrate with your QuickBooks software to quickly and easily handle high volume mailing projects.',
    TitleTag: '1" x 2 5/8" Mailing Address Labels for QuickBooks Desktop',
  },
  '/supplies/labels/address-labels-bml61': {
    MetaDescription:
      '1"x4", 20 per sheet labels integrate with QuickBooks Desktop to quickly and easily handle high volume mailing projects.',
    TitleTag: '1"x4" White Mailing and Address Labels for QuickBooks Desktop',
  },
  '/supplies/labels/return-address-labels-bml67': {
    MetaDescription:
      '1/2" x 1 3/4" return address labels integrate with QuickBooks Desktop to quickly and easily create return address adhesive labels.',
    TitleTag: '1/2" x 1 3/4" Return Address Labels for QuickBooks Desktop',
  },
  '/supplies/binders/away-check-binder': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'Office And Away Checks Binder And Organizer',
  },
  '/stampsandlabels/check-binders': {
    MetaDescription:
      'QuickBooks check binders keep you organized in style. Our binders let you keep your Office & Away and 3-To-A-Page General Purpose Manual Checks on hand.',
    TitleTag: 'Business Check Binder: 7 Ring Checkbook Binders 3-to-Page',
  },
  '/stampsandlabels/confidential-stamp': {
    MetaDescription:
      'Confidential stamp helps to ensure confidential documents remain confidential.',
    TitleTag: 'Confidential Stamp: QuickBooks Rubber Office Stamps',
  },
  '/stampsandlabels/copy-stamp': {
    MetaDescription:
      'Copy stamp helps to easily distinguish between original files and copies for physical review or records. ',
    TitleTag: 'Copy Stamp: QuickBooks Rubber Office Stamps',
  },
  '/stampsandlabels/custom-stamps': {
    MetaDescription:
      'Personalized self-inking stamps that help make your return addresses and fax covers easier.',
    TitleTag: 'Custom Stamps: QuickBooks Custom Rubber Office Stamps',
  },
  '/stampsandlabels/draft-stamp': {
    MetaDescription:
      'Draft stamp clearly indicates document is a draft. Useful for physical copies that are in need of review.',
    TitleTag: 'Confidential Stamp: QuickBooks Office Stamps',
  },
  '/stampsandlabels/endorsement-stamps': {
    MetaDescription:
      'Quickly endorse your day’s deposit. QuickBooks endorsement stamps are easy to personalize and come pre-inked with up to five lines of text.',
    TitleTag: 'Check Endorsement Stamp For Deposit Only - Self Inking',
  },
  '/stampsandlabels/fax-cover-stamps': {
    MetaDescription:
      'QuickBooks fax cover stamps help reduce waste and avoid the hassle of fax cover sheets and acts as a permanent record stays with the fax.',
    TitleTag: 'Fax Header Fax Cover Stamps To Replace Cover Sheets',
  },
  '/stampsandlabels/file-copy-stamp': {
    MetaDescription:
      'File copy stamp identifies the copies you should retain for your physical records.',
    TitleTag: 'File Copy Stamp: QuickBooks Rubber Office Stamps',
  },
  '/supplies/labels/file-folder-labels-bol66': {
    MetaDescription: 'Conveniently sized 2/3 in. x 3 7/16 in. file folder labels.',
    TitleTag: '2/3" x 3 7/16" File Folder Labels for QuickBooks Desktop',
  },
  '/stampsandlabels/fixed-stamp': {
    MetaDescription:
      'Faxed stamp allows you to easily track which faxes you’ve already sent and still need to be sent.',
    TitleTag: 'Faxed Stamp: QuickBooks Rubber Office Stamps',
  },
  '/stampsandlabels/general-purpose-stamps': {
    MetaDescription:
      'Quickly and easily identify documents with QuickBooks stamps - CONFIDENTIAL, COPY, PAST DUE, VOID, URGENT and several other general purpose stamps available. ',
    TitleTag: 'Office Stamps: Business Rubber Stamps for Office Productivity',
  },
  '/stampsandlabels/laser-labels': {
    MetaDescription:
      'QuickBooks printable labels for laser and inkjet printers are designed to optimally integrate with QuickBooks software to ensure accuracy and efficiency.',
    TitleTag: 'Laser Labels - Printable Laser & Inkjet Labels for QuickBooks',
  },
  '/supplies/binders/manual-check-binder': {
    MetaDescription:
      'Checks, envelopes, & office supplies designed to work seamlessly with QuickBooks and Intuit products.',
    TitleTag: 'Manual Checking Binder: QuickBooks',
  },
  '/stampsandlabels/monogram-stamps': {
    MetaDescription:
      'Return address stamp with monogram includes up to five lines of customizable text for a professional look.',
    TitleTag: 'Custom Monogram Stamps: Monogram Mailing Stamps',
  },
  '/stampsandlabels/paid-stamp': {
    MetaDescription:
      'Avoid printing a second invoice. Use a QuickBooks PAID stamp to let customers know they are paid in full. Available in red ink.',
    TitleTag: 'Paid Stamp: Paid in Full Rubber Stamp for QuickBooks',
  },
  '/stampsandlabels/past-due-stamp': {
    MetaDescription: 'Past due stamps get noticed and add a sense of urgency to overdue invoices.',
    TitleTag: 'Past Due Stamp: QuickBooks Rubber Past Due Stamps',
  },

  '/stampsandlabels/return-address-stamps': {
    MetaDescription:
      'Save time and money with a custom return address stamp from Intuit. Avoid writing your return address & make plain envelopes appear more professional. ',
    TitleTag: 'Custom Personalized Return Address Stamps from QuickBooks',
  },
  '/stampsandlabels/security-stamps': {
    MetaDescription:
      'Use Intuit security stamps and avoid the need to shredding personal data, bank account and other forms of sensitive information.',
    TitleTag: 'Security Stamp: Identity Protection Blackout Stamp',
  },
  '/supplies/labels/shipping-labels': {
    MetaDescription:
      'Printable shipping labels designed to integrate with QuickBooks Shipping Manager for sending overnight/express or ground packages. ',
    TitleTag: 'QuickBooks Shipping Manager Labels: Printable Shipping Labels',
  },
  '/stampsandlabels/urgent-stamp': {
    MetaDescription:
      'Urgent stamp helps ensure that important physical documents are given top priority.',
    TitleTag: 'Urgent  Stamp: QuickBooks Rubber Mark Urgent Stamps',
  },
  '/stampsandlabels/void-stamp': {
    MetaDescription:
      'Void stamp helps ensure misprinted checks are not cashed or fall into the wrong hands for check fraud.',
    TitleTag: 'Void Stamp: QuickBooks Rubber VOID stamps',
  },
  '/checks/standard-value-pack': {
    MetaDescription:
      'Standard QuickBooks checks at a great value when you buy in bulk. Save the time reordering and save money on each check.',
    TitleTag: 'Standard Checks For Less | QuickBooks Bulk Business Checks',
  },
  '/supplies/check-storage-boxes': {
    MetaDescription:
      'Check storage boxes sized just right to conveniently store and keep track of check stubs and canceled checks. ',
    TitleTag: 'Check Storage Boxes: Store Check Stubs & Canceled Checks',
  },
  '/supplies/computer-forms': {
    MetaDescription:
      'QuickBooks printable business forms project a  professional look and print directly from your QuickBooks software. ',
    TitleTag: 'Business Forms: Printable Forms for QuickBooks',
  },
  '/supplies/security-pens': {
    MetaDescription:
      'QuickBooks Security Pen with Super Ink™ helps prevent check forgery by impregnating checks with unwashable ink -  stop check forgery today',
    TitleTag: 'Security Pens & Security Ink: Prevent Check Washing Forgery',
  },
  '/supplies/stamps-labels': {
    MetaDescription:
      'Save time and look more professional with QuickBooks stamps and labels -- designed specifically to work with QuickBooks products.',
    TitleTag: 'Ink Stamps & Printable Labels: Pre-Inked Stamps & Print Labels',
  },
  '/tax-forms': {
    MetaDescription:
      'Get the only the printable tax forms that QuickBooks guarantees to work with all versions of QuickBooks.',
    TitleTag: 'Federal Tax Forms: Order QuickBooks Tax Forms to Print',
  },
  '/tax-forms/1096-forms': {
    MetaDescription:
      'Get 1096 forms designed to work with QuickBooks or to be filled out by hand and submitted to the IRS to summarize your other tax forms.',
    TitleTag: '1096 Tax Forms: Printable For QuickBooks & IRS Submittal',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® 1096 Forms", "sku": "TAX-04", "mpn": "178-00010", "brand":"QuickBooks","description":"Use Quickbooks® 1096 Forms to summarize the 1099 forms your company files. Get 4 free 1096 forms with each 1099 kit purchase." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_96_120.png", "offers" : { "@type" : "Offer", "price" : "17.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/1096-forms" } }',
  },
  '/tax-forms/1099-forms': {
    MetaDescription:
      'QuickBooks printable 1099 kits contain 1099 tax form and four FREE 1096 forms with envelopes - designed to work seamlessly with QuickBooks software.',
    TitleTag: 'Order 1099 NEC & MISC Forms & Envelopes To Print & File',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Pre-Printed 1099 Kits", "sku": "TAX-02", "mpn": "122-00010", "brand":"QuickBooks","description":"Use Quickbooks® 1099 Kits to report miscellaneous vendor payments of $600 or more. Kit contains 1099 forms, 4 free 1096 forms, and compatible envelopes." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_99_120.png", "offers" : { "@type" : "Offer", "price" : "83.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/1099-forms" } }',
  },
  '/tax-forms/w2-forms': {
    MetaDescription:
      'QuickBooks all-inclusive, IRS-approved W-2 form and envelope kits with guaranteed QuickBooks software compatibility are a great value.',
    TitleTag: 'QuickBooks Printable W2 Forms & W2 Form Envelope Kits',
  },
  '/tax-forms/w3-forms': {
    MetaDescription:
      'QuickBooks W-3 wage & tax statement forms for summarizing company W-2. Four free W-3 forms included with each W-2 kit.',
    TitleTag: 'W3 Form: W-3 Wage & Tax Statement Forms',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® W-3 Forms", "sku": "TAX-03", "mpn": "134-00010", "brand":"QuickBooks","description":"Print a summary of the W-2 forms submitted with a W-3 form.  Use to transmit wage and tax statements.  Get 4 free W-3 forms with each pre-printed W-2 kit purchase. An Intuit Marketplace product." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_w3_120.png", "offers" : { "@type" : "Offer", "price" : "17.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/w3-forms" } }',
  },
  '/checks/value-packs': {
    MetaDescription:
      'Receive a discount when you buy in bulk with Intuit Market checks. Save the time reordering and save money on each check.',
    TitleTag: 'A Cheaper Way to Buy Business Checks | QuickBooks Checks Value Packs',
  },
  '/checks/voucher-check-value-pack': {
    MetaDescription:
      'Buy voucher checks in bulk and get a discount. Save the time reordering and save money on each check. ',
    TitleTag: 'Voucher Checks For Less | QuickBooks Bulk Business Checks',
  },
  '/w2-kits': {
    MetaDescription:
      'QuickBooks all-inclusive, IRS-approved W-2 kits with guaranteed QuickBooks software compatibility are a great value. Available in packages of 10-600 forms.',
    TitleTag: 'W2 Form Kits With Envelopes: IRS Approved & QuickBooks Compatible',
  },
  '/tax-forms/w2-forms/2017-pre-printed-w-2-kits': {
    MetaDescription:
      'QuickBooks all-inclusive, IRS-approved W-2 kits with guaranteed QuickBooks software compatibility are a great value. Available in packages of 10-600 forms.',
    TitleTag: 'W2 Form Kits With Envelopes: IRS Approved & QuickBooks Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Pre-Printed W-2 Kits", "sku": "TAX-01", "mpn": "202-00010", "brand":"QuickBooks","description":"Quickbooks® Pre-Printed W-2 Kits contain W-2 forms, 4 free W-3 forms, and compatible envelopes. Report employee wages and salaries.  For Quickbooks® Payroll subscribers with a supported version of QuickBooks." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_w2k_120.gif", "offers" : { "@type" : "Offer", "price" : "83.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/w2-forms/2017-pre-printed-w-2-kits" } } ',
  },
  '/tax-forms/w2-forms/pre-printed-w-2-kits': {
    MetaDescription:
      'QuickBooks all-inclusive, IRS-approved W-2 kits with guaranteed QuickBooks software compatibility are a great value.',
    TitleTag: 'W2 Tax Form Kits To Print | IRS Approved & Quickbooks Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Pre-Printed W-2 Kits", "sku": "TAX-01", "mpn": "202-00010", "brand":"QuickBooks","description":"Quickbooks® Pre-Printed W-2 Kits contain W-2 forms, 4 free W-3 forms, and compatible envelopes. Report employee wages and salaries.  For Quickbooks® Payroll subscribers with a supported version of QuickBooks." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/tax/tax_w2k_120.gif", "offers" : { "@type" : "Offer", "price" : "83.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/tax-forms/w2-forms/2017-pre-printed-w-2-kits" } } ',
  },
  '/wce-details': {
    MetaDescription:
      'Wallet check envelopes & office supplies designed to work seamlessly with QuickBooks and QuickBooks Payroll.',
    TitleTag: 'Wallet Check Envelopes: QuickBooks & Payroll Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Wallet Check Envelopes", "sku": "ENV-02", "mpn": "738-00250", "brand":"QuickBooks","description":"Intuit QuickBooks® double-window check envelopes are custom-made for Intuit Wallet Checks. Check addresses align perfectly in the envelope windows, and confidential information remains hidden. Designed exclusively for use with QuickBooks®." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_dw_wallet_120.gif", "offers" : { "@type" : "Offer", "price" : "49.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/wce-details" } }',
  },
  '/wpce': {
    MetaDescription:
      'Wallet plus check envelopes & office supplies designed to work seamlessly with QuickBooks and QuickBooks Payroll.',
    TitleTag: 'Wallet Plus Check Envelopes: QuickBooks & Payroll Compatible',
    ProductSchema:
      '{ "@context" : "http://schema.org", "@type" : "Product", "name" : "QuickBooks® Wallet Plus Check Envelopes", "sku": "ENV-03", "mpn": "538-00250", "brand":"QuickBooks","description":"Save paper and reduce costs by using QuickBooks® full-size envelopes that feature an inside pocket to hold the Intuit QuickBooks® Wallet Check, so you can send a check and documents in the same envelope. Designed exclusively for use with QuickBooks®." ,"image" : "https://intuitmarket.intuitcdn.net/fsg_repository/env/env_dw_walletplus_120.gif", "offers" : { "@type" : "Offer", "price" : "54.99", "priceCurrency":"USD", "availability": "InStock", "priceValidUntil": "2099-01-01", "url": "https://intuitmarket.intuit.com/wpce" } }',
  },
  /* Added for newly designed PD pages - JIRA - 8109 */
  '/envelopes': {
    MetaDescription:
      'Save time, keep checks secure and professional looking with QuickBooks business envelopes. Perfectly sized for compatibility with QuickBooks checks.',
    TitleTag: 'Business Envelopes: Business Sized Envelopes for QuickBooks',
  },
  '/supplies': {
    MetaDescription:
      'QuickBooks office and point of sales supplies are quality-made, accurate, and will save you time during your day.',
    TitleTag: 'Office and Point of Sales Supplies',
  },
  '/supplies/stamps': {
    MetaDescription:
      'QuickBooks self-inking stamps give you better quality impressions and save you money with easily-replaceable ink refill pads.',
    TitleTag: 'Self-Inking Custom Stamps With Replaceable Ink Pads',
    Keywords: 'Ink Stamps, Pre-Inked Stamps',
  },
  '/supplies/labels/laser-labels': {
    MetaDescription:
      'Save time and ensure accuracy with printable labels that are guaranteed tointegrate with QuickBooks software.',
    TitleTag: 'Printable Laser And Inkjet Labels For QuickBooks',
    Keywords: 'printable labels',
  },
  '/supplies/binders': {
    MetaDescription:
      'QuickBooks check binders keep you organized in style. Our binders let you keep Office & Away and 3-To-A-Page General Purpose Manual Checks secure.',
    TitleTag: 'Business Check Binder: 7 Ring Checkbook Binders 3-to-Page',
  },
  '/supplies/forms': {
    MetaDescription:
      'QuickBooks printable business forms project a  professional look and print directly from your QuickBooks software. ',
    TitleTag: 'Business Forms: Printable Forms for QuickBooks',
  },
  '/supplies/forms/business-statements': {
    MetaDescription:
      'Streamline billing correspondence with QuickBooks business statements. Clearly communicate customer account status and make simplify remittance. ',
    TitleTag: 'Statement Forms: Business, Remittance, Billing & Financials',
  },
  '/supplies/forms/invoices': {
    MetaDescription:
      'QuickBooks preprinted invoice forms integrate seamlessly with QuickBooks software and come in three useful designs.',
    TitleTag: 'Blank Business Invoices: Invoice Forms & Templates',
  },
  '/supplies/forms/multi-purpose-forms': {
    MetaDescription:
      'Use high-quality, pre-printed Intuit forms for issuing financial communications directly from QuickBooks Desktop.',
    TitleTag: 'Pre-Printed Forms For Issuing Financial Communications',
  },
  '/discounts': {
    MetaDescription:
      'Save money on Intuit & QuickBooks Checks & Supplies with Intuit coupon codes. Get the correct supplies at a discount with the convenience of online ordering.',
    TitleTag: 'Intuit Discount Coupon Code: QuickBooks Check & Supplies Promo Codes',
  },
  '/cart': {
    MetaDescription: '',
    TitleTag: 'Intuit Market Shopping Cart',
  },
  '/shipping': {
    MetaDescription: '',
    TitleTag: 'Intuit Market Check Out: Shipping Information',
  },
  '/billing': {
    MetaDescription: '',
    TitleTag: 'Intuit Market Check Out: Payment Information',
  },
  '/summary': {
    MetaDescription: '',
    TitleTag: 'Intuit Market Check Out: Order Review',
  },
  '/confirmation': {
    MetaDescription: '',
    TitleTag: 'Intuit Market - Thank you for your order',
  },
  '/interruptivexsell': {
    MetaDescription: '',
    TitleTag: 'Frequently Bought Together',
  },
  /* Added for newly designed PD pages - JIRA - 8109 */
  /* UBERPSG-9922 QuickBooks Detect & Defend Update 2.6 */
  '/qb-detect-and-defend': {
    MetaDescription:
      "Take the critical steps to help protect your business' identity and help prevent corporate fraud. Learn how Detect & Defend monitors the Dark Web for your business' sensitive information, monitors your business credit report for suspicious activity, and helps you recover if you are the victim of identity theft.",
    TitleTag: 'Helping Protect Businesses From Identity Theft | QuickBooks Detect & Defend',
  },
  '/qb-detect-and-defend/features': {
    MetaDescription:
      'QuickBooks Detect & Defend helps protect businesses against identity theft and fraud while helping them quickly respond to data breaches.',
    TitleTag:
      'Dark Web Monitoring, Business Credit Monitoring and Identity Theft Resolution | QuickBooks Detect & Defend',
  },
  '/qb-detect-and-defend/what-is-business-identity-theft': {
    MetaDescription:
      'Learn the facts about business identity theft, data security, and how thieves exploit complacent businesses to commit fraud.',
    TitleTag: 'Business Identity And Fraud Education | QuickBooks Detect & Defend',
  },
  '/shipping-handling-faq': {
    MetaDescription:
      'Get checks and supplies delivered right to you from QuickBooks Checks & Supplies. Rely on our experience with our quick and easy order and delivery process.',
    TitleTag: 'QuickBooks Checks & Supplies Shipping And Handling FAQs',
  },
  '/qb-detect-and-defend/faq': {
    MetaDescription:
      'Frequently asked questions about QuickBooks Detect & Defend features and support.',
    TitleTag: 'FAQ | QuickBooks Detect & Defend',
  },
  /* added for IM search page */
  '/search': {
    MetaDescription: '',
    TitleTag: 'Search Results - QuickBooks Checks & Supplies',
  },
  'orderstatus/lookup': {
    MetaDescription: 'Use our order search to quickly see the status of your order.',
    TitleTag: 'Check Your Order Status | QuickBooks Checks & Supplies',
  },
  'orderstatus/detail': {
    MetaDescription: '',
    TitleTag: 'Order Status',
  },
  '/orderstatus/list': {
    MetaDescription: '',
    TitleTag: 'Order Status',
  },
  '/qb-detect-and-defend/resource-center/guide-business-identity-theft-101': {
    MetaDescription: 'Guide business identity theft 101',
    TitleTag: 'Guide business identity theft 101 | QuickBooks Detect & Defend',
  },
  '/qb-detect-and-defend/30-day-trial': {
    MetaDescription: '30 Day Trial',
    TitleTag: '30 Day Trial | QuickBooks Detect & Defend',
  },
  '/in-product-landing-page': {
    MetaDescription: '',
    TitleTag: 'Small Business Supplies from Intuit Market | QuickBooks',
  },
  '/tax-products/organizer-envelopes': {
    MetaDescription:
      'Save time mailing tax organizers and other documents. Customizable, slip sheet compatible and pre-printed envelopes for accountants and their clients.',
    TitleTag: 'Tax Organizer Envelopes: Pre-Printed & Slip Sheet Compatible',
  },
  '/tax-products': {
    MetaDescription:
      'Tax and office supplies designed for accountants. Forms, envelopes, and folders from the industry leader, Intuit.',
    TitleTag: 'Supplies For Accountants, CPA’s  & Professional Tax Preparers',
  },
  '/tax-products/tax-return-folders': {
    MetaDescription:
      'Present tax returns to your clients in a professional and custom folder. Designed for accountants by an industry leader in tax supplies.',
    TitleTag: 'Tax Return Folders: Customized For Accountant Presentations',
  },
  '/tax-products/tax-filing-envelopes': {
    MetaDescription:
      "Ensure your clients' returns are mailed to the correct filing center, without printing multiple labels. From the tax and accounting experts at Intuit.",
    TitleTag: 'Tax Return Envelopes for Tax Preparers and Accountants',
  },
  '/tax-products/tax-return-folders/large-window-premium-tax-return-folders': {
    MetaDescription:
      "Get QuickBooks premium, professional grade large window tax return folders organize your clients' documents.",
    TitleTag: 'Large Window Premium Tax Return Folders | QuickBooks',
  },
  '/tax-products/tax-return-folders/two-pocket-folders': {
    MetaDescription:
      "Get QuickBooks premium, professional grade two pocket folders for tax returns to organize your clients' documents.",
    TitleTag: 'Two Pocket Folders For Tax Returns | QuickBooks',
  },
  '/tax-products/tax-return-folders/standard-tax-return-folders': {
    MetaDescription:
      'Get QuickBooks professional grade standard tax return folders that are professional and functional for accountants.',
    TitleTag: 'Standard Tax Return Folders | QuickBooks',
  },
  '/tax-products/tax-return-folders/small-window-premium-tax-return-folders': {
    MetaDescription:
      "Get QuickBooks premium, professional grade small window premium tax return folders to organize your clients' documents.",
    TitleTag: 'Small Window Premium Tax Return Folders | QuickBooks',
  },
  '/help': {
    MetaDescription:
      'Learn everything you need to know about your Intuit Market account, order, product, or the best way to contact us.',
    TitleTag: 'Help With Your Intuit Market Account, Ordering, Or Products',
  },
  '/help/account': {
    MetaDescription:
      "Intuit stands behind our products with a 60-day guarantee and policies and we're dedicated to keeping your information private.",
    TitleTag: 'TOS Product & Customer Policies & Guarantees | QuickBooks',
  },
  '/help/contact-intuit-market': {
    MetaDescription:
      'Learn all the details and the best way to contact us online or on the phone during office hours or send us a request by mail.',
    TitleTag: 'How And When To Contact Intuit Marketplace | QuickBooks',
  },
  '/help/customization': {
    MetaDescription:
      'Learn how to get custom, professional, and secure checks and supplies that are guaranteed to work with QuickBooks.',
    TitleTag: 'Custom Checks and Supplies With Business Logos | QuickBooks',
  },
  '/help/ordering-and-shipping': {
    MetaDescription:
      'Intuit makes it easy to order, reorder, manage your orders and shipping options. Learn the quick tips to maximize your account and supplies.',
    TitleTag: 'How To Order, Reorder, Manage Orders & Shipping | QuickBooks',
  },
  '/help/migrate-from-ie11': {
    MetaDescription: '',
    TitleTag: 'Move to a New Browser to Continue Using Your Account',
  },
  '/help/termsofservice': {
    MetaDescription: '',
    TitleTag: 'QuickBooks Checks & Supplies Terms Of Service | Intuit',
  },
  // Voucher Check Value Pack Promotion Pages
  '/checks/voucher-check-value-packs': {
    MetaDescription:
      'Receive a discount when you buy Voucher Check Bundles from QuickBooks Checks and Supplies. Save money on the supplies you need.',
    TitleTag: 'Save On Essentials For Your Business | Quickbooks Voucher Checks Value Packs',
  },
  '/checks/secure-premier-check-value-pack': {
    MetaDescription:
      'Get QuickBooks Secure Premier Voucher Checks with a bundle discount. Save time and money and help protect your business from fraud with our most secure checks.',
    TitleTag: 'Discounts On Secure Checks | Quickbooks Voucher Checks Value Packs',
  },
  '/checks/secure-plus-check-value-pack': {
    MetaDescription:
      'Get QuickBooks Secure Plus Voucher Checks with a bundle discount. Save time and money on the supplies you need while helping protect your business from fraud.',
    TitleTag: 'Save On Our Most Secure Checks | Quickbooks Voucher Checks Value Packs',
  },
  '/checks/basic-check-value-pack': {
    MetaDescription:
      'Get QuickBooks Basic Voucher Checks with a bundle discount. Save time and money on the supplies you need.',
    TitleTag: 'Discounted Business Checks And Supplies | Quickbooks Voucher Checks Value Packs',
  },
  '/resources/fraud-prevention/how-to-dispose-of-checks': {
    MetaDescription:
      'Learn the four best ways to dispose of your unused or cashed business checks in the safest possible ways to prevent fraud. ',
    TitleTag: 'How To Properly Dispose Of Business Checks',
  },
  '/resources/checks/check-printing-software': {
    MetaDescription:
      'What your business needs in check writing software for efficient and accurate accounts payable operations.',
    TitleTag: '5 Check Writing Software Must-Haves For Your Business',
  },
  default: {
    MetaDescription: '',
    TitleTag: 'Small Business Supplies from Intuit Market | QuickBooks',
  },
};

export const signInWidgetName = {
  SignIn: 'SignIn',
  QbFormsSignIn: 'QbFormsSignIn',
  GlobalNavSignIn: 'GlobalNavSignIn',
  RetrieveSignIn: 'RetrieveSignIn',
  WelcomeBackSignIn: 'WelcomeBackSignIn',
  ReorderSignIn: 'ReorderSignIn',
  ReorderChecksSignIn: 'ReorderChecksSignIn',
  LogoUploadSignIn: 'LogoUploadSignIn',
  SaveWorkSignIn: 'SaveWorkSignIn',
  MobileSignIn: 'MobileSignIn',
  CartSignIn: 'CartSignIn',
};

export const ReduxEvent = {
  DATA_LOAD_SUCCESS: 'DATA_LOAD_SUCCESS',
  INITIALIZE_INITIAL_PAGE_SUCCESS: 'INITIALIZE_INITIAL_PAGE_SUCCESS',
  GET_CLIENT_CONFIG_SUCCESS: 'GET_CLIENT_CONFIG_SUCCESS',
  HEADER_UPDATE: 'HEADER_UPDATE',
  PAGE_DATA: 'PAGE_DATA',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SHOPPER_ACCOUNT_SUCCESS: 'SHOPPER_ACCOUNT_SUCCESS',
  ORDER_STATUS_SUCCESS: 'ORDER_STATUS_SUCCESS',
  GET_PRODUCT_DATA_SUCESS: 'GET_PRODUCT_DATA_SUCESS',
  ORDER_SELECTED: 'ORDER_SELECTED',
  RETRIEVE_ORDER_SUCCESS: 'RETRIEVE_ORDER_SUCCESS',
  RETRIEVE_ORDER_MOVE_STEP: 'RETRIEVE_ORDER_MOVE_STEP',
  RETRIEVE_ORDER_GET_SECURITY_QUESTION_SUCCESS: 'RETRIEVE_ORDER_GET_SECURITY_QUESTION_SUCCESS',
  RETRIEVE_ORDER_TOGGLE_LOADING: 'RETRIEVE_ORDER_TOGGLE_LOADING',
  RETRIEVE_ORDER_REORDER_SUCCESS: 'RETRIEVE_ORDER_REORDER_SUCCESS',
  RETRIEVE_ORDER_RESET_INTIAL: 'RETRIEVE_ORDER_RESET_INTIAL',
  ORDER_LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
  TAX_SEASON: 'TAX_SEASON',
  GET_CUST_IMPRINT_DETAILS_SUCCESS: 'GET_CUST_IMPRINT_DETAILS_SUCCESS',
  COMPANY_INFO_TEXT_CHANGED: 'COMPANY_INFO_TEXT_CHANGED',
  SUMMARY_TEXT_CHANGED: 'SUMMARY_TEXT_CHANGED',
  BANK_SELECTION_CHANGE: 'BANK_SELECTION_CHANGE',
  ROUTING_NUMBER_CHANGE: 'ROUTING_NUMBER_CHANGE',
  ACCOUNT_NUMBER_CHANGE: 'ACCOUNT_NUMBER_CHANGE',
  CUST_BANK_INFO_CHANGED: 'CUST_BANK_INFO_CHANGED',
  CUSTOMIZATION_MICR_CHANGED: 'CUSTOMIZATION_MICR_CHANGED',
  CUSTOMIZATION_MICR_CHANGED_CSN: 'CUSTOMIZATION_MICR_CHANGED_CSN',
  CUSTOMIZATION_INTIALS_CHANGED: 'CUSTOMIZATION_INTIALS_CHANGED',
  CUSTOMIZATION_SHEET_SELECTION_CHANGED: 'CUSTOMIZATION_SHEET_SELECTION_CHANGED',
  CUSTOMIZATION_SKU_SELECTION_CHANGED: 'CUSTOMIZATION_SKU_SELECTION_CHANGED',
  CUSTOMIZATION_VALIDATION_ERROR_TOGGLE: 'CUSTOMIZATION_VALIDATION_ERROR_TOGGLE',
  CUSTOMIZATION_RESET_STATE: 'CUSTOMIZATION_RESET_STATE',
  CUSTOMIZATION_TOGGLE_LOADING: 'CUSTOMIZATION_TOGGLE_LOADING',
  CUSTOMIZATION_UPDATE_ANY_STATE: 'CUSTOMIZATION_UPDATE_ANY_STATE',
  CART_VISITED: 'CART_VISITED',
  FONT_FAMILY_CHANGED: 'FONT_FAMILY_CHANGED',
  FONT_COLOR_CHANGED: 'FONT_COLOR_CHANGED',
  FONT_SIZE_CHANGED: 'FONT_SIZE_CHANGED',
  CUSTOMIZATION_LOGO_CHANGED: 'CUSTOMIZATION_LOGO_CHANGED',
  UPDATE_SAME_AS_SHIPPING: 'UPDATE_SAME_AS_SHIPPING',
  SAME_AS_SHIPPING: 'SAME_AS_SHIPPING',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  CUSTOMIZATION_PRINTER_TYPE_CHANGED: 'CUSTOMIZATION_PRINTER_TYPE_CHANGED',
  CUSTOMIZATION_SHADOW_LOGO_CHANGED: 'CUSTOMIZATION_SHADOW_LOGO_CHANGED',
  ORDER_CONFIRMED: 'ORDER_CONFIRMED',
  CUSTOM_IMAGE_SELECTED: 'CUSTOM_IMAGE_SELECTED',
  CUSTOMIZATION_SAGA_ERROR: 'CUSTOMIZATION_SAGA_ERROR',
  IS_BILLING_EDIT: 'IS_BILLING_EDIT',
  CC_ERROR_ORDER_PLACED: 'CC_ERROR_ORDER_PLACED',
  CUSTOMIZATION_SKU_CATEGORY_SELCTION: 'CUSTOMIZATION_SKU_CATEGORY_SELCTION',
  CHECK_STARTING_NUMBER: 'CHECK_STARTING_NUMBER',
  ENV_REQUIRED: 'ENV_REQUIRED',
  SIGNATURE_LINES_SELECTED: 'SIGNATURE_LINES_SELECTED',
  ACCOUNT_OPEN_DATE: 'ACCOUNT_OPEN_DATE',
  FRAUD_DETECT_RESULT: 'FRAUD_DETECT_RESULT',
  BACK_TO_PAYMENT_CC: 'BACK_TO_PAYMENT_CC',
  COMBO_BOX_LIST: 'COMBO_BOX_LIST',
  RESET_SELECTED_BANK: 'RESET_SELECTED_BANK',
  IS_ACCOUN_ROUTING_CHANGED: 'IS_ACCOUN_ROUTING_CHANGED',
};

export const SagaEvent = {
  INITIALIZE_INITIAL_PAGE_LOAD: 'INITIALIZE_INITIAL_PAGE_LOAD',
  GET_CUST_IMPRINT_DETAILS: 'GET_CUST_IMPRINT_DETAILS',
  CUSTOMIZATION_SAVE: 'CUSTOMIZATION_SAVE',
  UPGRADE_CHECKS: 'UPGRADE_CHECKS',
};

export const MetaTagRobotsNoFollowRoutes = [
  '/orderstatus/detail',
  '/orderstatus/list',
  '/oa-home',
  '/oa-tax',
  // not sure if this route exists
  '/qb-detect-and-defend/resource-center/guide-business-identity-theft-101',
  '/qb-detect-and-defend/resource-center/guide-risky-business-the-hidden-costs',
  '/qb-detect-and-defend/resource-center/guide-what-is-the-dark-web',
  '/qb-detect-and-defend/resource-center/guide-my-business-has-a-credit-score',
  '/qb-detect-and-defend/30-day-trial',
  '/qb-detect-and-defend/resource-center/email-6-common-small-business-scams',
  '/in-product-landing-page',
  '/tax-forms/1099-nec-forms',
  '/secure-products',
  '/cart',
  '/checks/basic-check-value-pack',
  '/checks/standard-value-pack',
  '/checks/value-packs',
  '/spsc',
  '/checks/voucher-check-value-pack',
  '/shipping-handling-faq',
  '/orderstatus/lookup',
  '/bsbc',
  '/bvc',
  '/spsbc',
  '/spvc',
  '/spvcpremier',
  '/spwc',
  '/wce-details',
  '/wpce',
  '/help/migrate-from-ie11',
  // Voucher Check Value Pack Promotion Pages
  '/checks/voucher-check-value-packs',
  '/checks/secure-premier-check-value-pack',
  '/checks/secure-plus-check-value-pack',
  '/reorder',
  '/signin',
];

export const MetaTagRobotsFollowRoutes = [
  '/orderhistory',
  '/retrievepreviousorder',
  '/search',
  '/shipbill',
  '/checks/voucher-checks/secure-premier-voucher-checks',
  '/checks/wallet-checks/secure-plus-wallet-checks',
  '/signout',
  '/checks/wallet-checks/basic-wallet-checks',
  '/blankperfw2',
  '/fe',
  '/savedworklogo',
  '/checks/voucher-checks/basic-voucher-checks',
  '/checks/voucher-checks/secure-plus-voucher-checks',
  '/checks/standard-checks/secure-premier-standard-business-checks',
  '/checks/standard-checks/secure-plus-standard-business-checks',
  '/checks/standard-checks/basic-standard-business-checks',
];

export const MetaTagRobotsNoIndexRoutes = [];

export const FeatureConfiguration = {
  site: 'https://www.intuitmarket.com',
  plugins: [
    {
      name: 'navigation',
      status: 'off',
    },
  ],
  features: [
    {
      name: 'performance',
      status: 'on',
    },
    {
      name: 'analytics',
      status: 'on',
    },
    {
      name: 'iuslogin',
      status: 'on',
    },
    {
      name: 'SEO',
      status: 'on',
    },
    {
      name: 'MigratedCustomization',
      status: 'on',
    },
    {
      name: 'clienterrorlog',
      status: 'on',
    },
    {
      name: 'resourcetimings',
      status: 'on',
    },
    {
      name: 'usertimings',
      status: 'on',
    },
    {
      name: 'bandwidth',
      status: 'on',
    },
    {
      name: 'ipdcheckoutforim',
      status: 'on',
    },
    {
      name: 'immyaccount',
      status: 'on',
    },
    {
      name: 'chatbot',
      status: 'off',
    },
    {
      name: 'isPrivacyLinkAvailable',
      status: 'True',
    },
  ],
};

export const StateTax = [
  {
    StateCode: 'AK',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'AL',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'AR',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'AZ',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'CA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'CO',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'CT',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'DC',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'DE',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'FL',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'GA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'HI',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 5,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'IA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'ID',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'IL',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'IN',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'KS',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'KY',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'LA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MD',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'ME',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MI',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MN',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MO',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MS',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'MT',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'NC',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'ND',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'NE',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'NH',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'NJ',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'NM',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'NV',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'NY',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'OH',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'OK',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'OR',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'PA',
    PartsFileCityIncomeTax_W2_Yes: 8,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: true,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'RI',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'SC',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'SD',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'TN',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'TX',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'UT',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'VA',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'VT',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'WA',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'WI',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 5,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: true,
  },
  {
    StateCode: 'WV',
    PartsFileCityIncomeTax_W2_Yes: 6,
    PartsFileCityIncomeTax_W2_No: 6,
    PartsFileFedCityIncomeTax_1099_Yes: 4,
    PartsFileFedCityIncomeTax_1099_No: 4,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
  {
    StateCode: 'WY',
    PartsFileCityIncomeTax_W2_Yes: 4,
    PartsFileCityIncomeTax_W2_No: 4,
    PartsFileFedCityIncomeTax_1099_Yes: 3,
    PartsFileFedCityIncomeTax_1099_No: 3,
    Ask_w2_question_yn: false,
    Ask_1099_question_yn: false,
  },
];

export const colorPickerList = [
  {
    color: 'Regal',
    imageurl: RegalIcon,
  },
  {
    color: 'Hunter',
    imageurl: HunterIcon,
  },
  {
    color: 'Charcoal',
    imageurl: CharcoalIcon,
  },
  {
    color: 'Bramble',
    imageurl: BrambleIcon,
  },
  {
    color: 'Green',
    imageurl: GreenIcon,
  },
  {
    color: 'Blue',
    imageurl: BlueIcon,
  },
];

export const RetrieveOrderModule = {
  find: 'find',
  orderList: 'orderList',
  orderVerification: 'orderVerification',
  emailCheckNumber: 'emailCheckNumber',
  postEmailOrderVerification: 'postEmailOrderVerification',
  previousOrder: 'previousOrder',
};

export const SecurityCheckQuestionsFor = {
  ORDER_VERIFICATION: 'ORDER_VERIFICATION',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
};

export const IPDMigratedCustomization = {
  migratedRoutes: [
    'sppccust',
    'mcheckcust',
    'officeawaycust',
    'checks/standard-checks/basic-standard-business-checks',
    'checks/standard-checks/secure-plus-standard-business-checks',
    'checks/standard-checks/secure-premier-standard-business-checks',
    'checks/voucher-checks/basic-voucher-checks',
    'checks/voucher-checks/secure-plus-voucher-checks',
    'checks/voucher-checks/secure-premier-voucher-checks',
    'checks/wallet-checks/basic-wallet-checks',
    'checks/wallet-checks/secure-plus-wallet-checks',
  ],
  routepfid: {
    sppccust: 'CHECK-15',
    mcheckcust: 'MCHECK-01',
    officeawaycust: 'CHECK-04',
    'checks/standard-checks/basic-standard-business-checks': 'CHECK-01',
    'checks/standard-checks/secure-plus-standard-business-checks': 'CHECK-12',
    'checks/standard-checks/secure-premier-standard-business-checks': 'CHECK-17',
    'checks/voucher-checks/basic-voucher-checks': 'CHECK-02',
    'checks/voucher-checks/secure-plus-voucher-checks': 'CHECK-11',
    'checks/voucher-checks/secure-premier-voucher-checks': 'CHECK-16',
    'checks/wallet-checks/basic-wallet-checks': 'CHECK-09',
    'checks/wallet-checks/secure-plus-wallet-checks': 'CHECK-14',
  },
  pfidroute: {
    'CHECK-02': 'checks/voucher-checks/basic-voucher-checks',
    'CHECK-11': 'checks/voucher-checks/secure-plus-voucher-checks',
    'CHECK-16': 'checks/voucher-checks/secure-premier-voucher-checks',
    'CHECK-01': 'checks/standard-checks/basic-standard-business-checks',
    'CHECK-17': 'checks/standard-checks/secure-premier-standard-business-checks',
    'CHECK-12': 'checks/standard-checks/secure-plus-standard-business-checks',
    'CHECK-14': 'checks/wallet-checks/secure-plus-wallet-checks',
    'CHECK-09': 'checks/wallet-checks/basic-wallet-checks',
    // Added to show check09 new customization for check 03 in reorder scenario.
    'CHECK-03': 'checks/wallet-checks/basic-wallet-checks',
    'CHECK-15': 'sppccust',
    'MCHECK-01': 'mcheckcust',
    'CHECK-04': 'officeawaycust',
  },
  routeenv: {
    sppccust: 'ImAppCC',
    mcheckcust: 'ImManualChecks',
    officeawaycust: 'ImManualChecks',
    'checks/standard-checks/basic-standard-business-checks': 'ImAppCC',
    'checks/standard-checks/secure-plus-standard-business-checks': 'ImAppCC',
    'checks/standard-checks/secure-premier-standard-business-checks': 'ImAppCC',
    'checks/voucher-checks/basic-voucher-checks': 'ImAppCC',
    'checks/voucher-checks/secure-plus-voucher-checks': 'ImAppCC',
    'checks/voucher-checks/secure-premier-voucher-checks': 'ImAppCC',
    'checks/wallet-checks/basic-wallet-checks': 'ImAppCC',
    'checks/wallet-checks/secure-plus-wallet-checks': 'ImAppCC',
  },
};
export const getOrderStatusExpiryDays = 21;

export const validStatesInUS = [
  'AA',
  'AE',
  'AP',
  'AS',
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'MH',
  'MP',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'VI',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const customizationRoutes = {
  'CHECK-02': 'bvc',
  'CHECK-11': 'spvc',
  'CHECK-16': 'spvcpremier',
  'DEP-01': 'mds',
  'DEP-02': 'pds',
  'CHECK-01': 'bsc',
  'CHECK-12': 'spsc',
  'CHECK-17': 'spsbc',
  'CHECK-09': 'bwc',
  'CHECK-14': 'spwc',
  'CHECK-04': 'officeaway',
  'CHECK-15': 'sppc',
  'MCHECK-01': 'mcheck',
  'MULTIFORM-01': 'ifrm',
  'DEP-ADV-01': 'ifrm',
  'STATE-01': 'ifrm',
  'STATE-02': 'ifrm',
  'STAMP-03': 'stamps',
  'STAMP-04': 'stamps',
  'STAMP-06': 'stamps',
  'STAMP-07': 'ifrm',
  'STAMP-08': 'ifrm',
  'STAMP-09': 'ifrm',
  'STAMP-10': 'ifrm',
  'STAMP-11': 'ifrm',
  'STAMP-12': 'ifrm',
  'STAMP-13': 'ifrm',
  'STAMP-17': 'ifrm',
  'STAMP-18': 'ifrm',
  'STAMP-19': 'stamps',
  'INV-01': 'ifrm',
  'INV-02': 'ifrm',
  'INV-03': 'ifrm',
  'LME-01': 'ifrm',
  'ENV-07': 'ifrm',
  'ENV-05': 'ifrm',
  'BUNDLE-01': 'bundle',
  'BUNDLE-02': 'bundle',
  'BUNDLE-03': 'bundle',
  'BUNDLE-06': 'bundle',
  'BUNDLE-07': 'bundle',
};

export const upperCaseAlphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const fontFamily = [
  {
    key: 'auriol',
    value: 'Auriol',
  },
  {
    key: 'calvert',
    value: 'Calvert',
  },
  {
    key: 'cascadescript',
    value: 'Cascade Script',
  },
  {
    key: 'helvetica',
    value: 'Helvetica',
  },
  {
    key: 'hobo',
    value: 'Hobo',
  },
  {
    key: 'improv',
    value: 'Improv',
  },
  {
    key: 'kaufmann',
    value: 'Kaufmann',
  },
  {
    key: 'korinthia',
    value: 'Korinna',
  },
  {
    key: 'palatino',
    value: 'Palatino',
  },
];

export const fontColor = [
  {
    color: 'Black',
    title: 'Black',
    price: 'free',
  },
  {
    color: 'Blue',
    title: 'Blue',
    price: '+$19.95',
  },
  {
    color: 'Green',
    title: 'Green',
    price: '+$19.95',
  },
  {
    color: 'Maroon',
    title: 'Maroon',
    price: '+$19.95',
  },
  {
    color: 'Red',
    title: 'Red',
    price: '+$19.95',
  },
  {
    color: 'Violet',
    title: 'Violet',
    price: '+$19.95',
  },
  {
    color: 'DoubleColor',
    title: 'Black plus a secondary color',
    price: '+$29.95',
  },
];

export const ErrorMessages = {
  CSN_Missing: 'Enter your starting check number. It should be between 3-6 digits.',
  CSN_Missing_PersonalCheck: 'Enter your starting check number. It should be between 3-4 digits.',
  CSN_3_6_Digits: 'Please provide a number between 3-6 digits.',
  CSN_3_4_Digits_PersonalCheck: 'Please provide a number between 3-4 digits.',
  CSN_AlphaNumeric:
    'The starting check number needs to be between 3-6 digits. For alphanumeric numbering, enter 1-2 letters followed by 3-6 digits. Alphanumeric numbering has a one-time set-up fee of $4.99',
  CSN_Length:
    'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
  CSN_Length_PersonalCheck:
    'The check starting number should be between 101-9999. Please provide your preferred check starting number.',
  CSN_Numeric: 'Check starting number should be numeric.',
};

export const Month = [
  {
    key: 'Month',
    value: '00',
  },
  {
    key: 'Jan',
    value: '01',
  },
  {
    key: 'Feb',
    value: '02',
  },
  {
    key: 'Mar',
    value: '03',
  },
  {
    key: 'Apr',
    value: '04',
  },
  {
    key: 'May',
    value: '05',
  },
  {
    key: 'Jun',
    value: '06',
  },
  {
    key: 'Jul',
    value: '07',
  },
  {
    key: 'Aug',
    value: '08',
  },
  {
    key: 'Sep',
    value: '09',
  },
  {
    key: 'Oct',
    value: '10',
  },
  {
    key: 'Nov',
    value: '11',
  },
  {
    key: 'Dec',
    value: '12',
  },
];

export const IusOffering = 'Intuit.qbc.ps.intuitmarket';

export const unavailableProducts = {
  pfid: [
    'TAX-36',
    'THERMAL-05',
    'THERMAL-01',
    'RECEIPT-01',
    'THERMAL-02',
    'THERMAL-03',
    'THERMAL-04',
  ],
  sku: [],
  baseSku: ['113'],
};

// This message is used every where except tas forms home page herro section
export const taxFormUnavailableMessage = `tax forms go on sale`;

export const getOutofstockProducts = (state) => {
  const pfid =
    state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockPfid &&
    state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockPfid.length > 0
      ? state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockPfid.split(',')
      : [];
  const baseSku =
    state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockBaseSku &&
    state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockBaseSku.length > 0
      ? state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockBaseSku.split(',')
      : [];
  const outofstockProducts = {
    pfid,
    sku: [],
    baseSku,
  };
  return outofstockProducts;
};

export const getOutofstockProductList = (listOfOutOfStockProducts) => {
  const pfid = listOfOutOfStockProducts[0].pfidList;
  const sku = listOfOutOfStockProducts[0].skuList;
  const outofstockProducts = {
    pfid,
    sku,
    baseSku: [],
  };
  return outofstockProducts;
};

export const getStockChangeText = (state) => {
  const pfid =
    state.mainReducer.clientConfigs.StockChangeTextForFolders &&
    state.mainReducer.clientConfigs.StockChangeTextForFolders.length > 0
      ? state.mainReducer.clientConfigs.StockChangeTextForFolders.split(',')
      : [];
  const stockChangeTextForPfids = {
    pfid,
  };
  return stockChangeTextForPfids;
};

export const nonTaxableStates = /^(CA|HI|NC|SC|WA|PR)$/i; // ineligible for tax exempt

export const CPRA_Location_Cookie_Value = `US~CA`;

export const CPRA_Location_Cookie_Key = `AKES_GEO`;

export const CPRA_Footer_Text = 'Your California Privacy Rights';

export const HostedUISignIn = {
  customizationReducer: 'customizationReducer',
  currentStep: 'currentStep',
};
