// eslint-disable-next-line require-jsdoc
import React, { useState } from 'react';
import { CloseIcon } from '@ids-ts/icon';
import Drawer from '@material-ui/core/Drawer';
import { Collapse } from '@material-ui/core';
import Button from '@ids/button';
import { ChevronLeftIcon } from '@ids-ts/icon';
import { useSelector } from 'react-redux';
import { AddLogoOption } from './AddLogoOption';
import { Monogram } from './Monogram';
import { addLogoOptions } from '../../Configuration/CustomizationConfiguration';
import { PreDesignedLogo } from './PreDesignedLogo';
import { UploadedLogo } from './UploadedLogo';
import { HostedUISignIn } from '../../../../../assets/pagedata/constant';
import HostedSignIn from '../../../Header/HostedSignIn';
// eslint-disable-next-line require-jsdoc
export const LogoDrawer = (props) => {
  const { open, AddLogoDisplayOptions, ModalConfiguration } = props;
  const [selectedLogoOption, setLogoOption] = useState(null);

  const mainReducer = useSelector((state) => state.mainReducer);
  const { pageData } = mainReducer;

  const [showSignIn, setShowSignIn] = useState(false);

  const onBtnClicked = (id) => {
    if (id === addLogoOptions.UPLOAD.ID && !pageData.login) {
      props.SaveImprintToLocalStorage();
      props.modalDismiss();
      setShowSignIn(true);
    } else {
      setLogoOption(id);
    }
  };

  const closeDrawer = () => {
    props.modalDismiss(null);
    setLogoOption(null);
    if (
      localStorage.getItem(HostedUISignIn.customizationReducer) ||
      localStorage.getItem(HostedUISignIn.currentStep)
    ) {
      localStorage.removeItem(HostedUISignIn.customizationReducer);
      localStorage.removeItem(HostedUISignIn.currentStep);
    }
  };

  const onLogoSelect = (logoDetails) => {
    const params = {
      logoOption: selectedLogoOption,
      logo: logoDetails,
    };
    props.modalDismiss(params);
    setLogoOption(null);
  };

  const closeSignInModal = () => {
    // props.modalOpen();
    setShowSignIn(false);
  };

  const isMainSectionCollapsed = () => {
    if (selectedLogoOption === addLogoOptions.UPLOAD.ID) {
      return pageData.login;
    }

    return !!selectedLogoOption;
  };

  return (
    <>
      <Drawer
        open={open}
        anchor="right"
        className="slide-upload-panel"
        style={{ zIndex: '999' }}
        onClose={closeDrawer}
        hideHeader
      >
        <div className="cust-upload-wrapper">
          <Collapse in={!isMainSectionCollapsed()}>
            {!ModalConfiguration.hideTitleSection ? (
              <>
                <h3 className="cust-upload-modal-header">
                  {ModalConfiguration.modalHeading ? ModalConfiguration.modalHeading : `Add a logo`}{' '}
                  <span
                    className="cust-close-upload"
                    onClick={closeDrawer}
                    style={{ marginTop: '-25px' }}
                  >
                    <CloseIcon size="small" />
                  </span>
                </h3>
                {/* {Title && <p className="p2-regular margin-bottom-20">{Title}</p>} */}
                <p className="margin-bottom-20 cust-upload-modal-text">
                  {ModalConfiguration.description}
                </p>
              </>
            ) : (
              <div>
                <span className="cust-close-upload" onClick={closeDrawer}>
                  <CloseIcon size="small" />
                </span>
              </div>
            )}

            {AddLogoDisplayOptions.map((el, key) => {
              return (
                <>
                  <AddLogoOption key={key} {...props} {...el} onButtonClick={onBtnClicked} />
                </>
              );
            })}
          </Collapse>
          <Collapse in={isMainSectionCollapsed()}>
            <h3 className="cust-upload-modal-header add-logo-options-container">
              <div className="cust-upload-header">
                <Button
                  theme="quickbooks"
                  buttonType="tertiary"
                  style={{ marginLeft: '-5px' }}
                  icon={<ChevronLeftIcon />}
                  onClick={() => setLogoOption(null)}
                >
                  Back to{' '}
                  {ModalConfiguration.modalHeading
                    ? ModalConfiguration.modalHeading.toLowerCase()
                    : `add a logo`}
                </Button>
                <span className="cust-close-upload" onClick={closeDrawer}>
                  <CloseIcon size="small" />
                </span>
              </div>

              {selectedLogoOption === addLogoOptions.MONOGRAM.ID && (
                <Monogram {...props} onLogoSelect={onLogoSelect} />
              )}

              {selectedLogoOption === addLogoOptions.PREDESIGN.ID && (
                <PreDesignedLogo
                  {...props}
                  CatalogLink={addLogoOptions.PREDESIGN.CatalogLink}
                  onLogoSelect={onLogoSelect}
                />
              )}

              {selectedLogoOption === addLogoOptions.UPLOAD.ID && pageData.login && (
                <UploadedLogo {...props} onLogoSelect={onLogoSelect} />
              )}
            </h3>
          </Collapse>
        </div>
      </Drawer>
      {showSignIn && (
        <HostedSignIn
          {...props}
          showModal={showSignIn}
          closeModal={closeSignInModal}
          location={window.location.pathname.slice(1)}
          imprintID={window.location.search}
          redirectCookie="/shipping"
        />
      )}
    </>
  );
};
